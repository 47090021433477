import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TransferItem } from 'ng-zorro-antd/transfer';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { NgxSuneditorComponent } from 'ngx-suneditor';
import { Observable, Observer, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Exam } from 'src/app/core/interfaces/exam';
import { LocalArray } from 'src/app/core/interfaces/local-array';
import { Test } from 'src/app/core/interfaces/test';
import { HttpRequestService } from 'src/app/core/services';
import { CkEditorConfigService } from 'src/app/core/services/ck-editor-config.service';
import { CommonArrayService } from 'src/app/core/services/common-array.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-add-update-test',
  templateUrl: './add-update-test.component.html',
  styleUrls: ['./add-update-test.component.scss'],
})
export class AddUpdateTestComponent implements OnInit {
  @ViewChildren(NgxSuneditorComponent) editors!: QueryList<NgxSuneditorComponent>;
  addUpdateTestForm!: FormGroup;
  idForUpdate: string;
  buttonLoading: boolean = false;
  imageLoading: boolean = false;
  checkAddPermission = false;
  checkUpdatePermission = false;
  search = '';
  examSearch = '';
  searchExam: Subject<string> = new Subject<string>();
  searchTestType: Subject<string> = new Subject<string>();
  allTestTypes: LocalArray[] = [];
  allExamType: LocalArray[] = [];
  allPublishedStatus: LocalArray[] = [];
  allExams: Exam[] = [];
  allLevels: LocalArray[] = [];
  time: Date | null = null;
  mediaBaseUrl = environment.mediaBaseUrl;
  searchTest: Subject<any> = new Subject<any>();
  allQuestions: TransferItem[] = [];
  switchValue = false;

  // allItems:any[]=[];

  sections: any[] = [];
  availableQuestionsTemp: any[] = [];
  mediaUploadUrl = environment.apiBaseUrl + '/api/media';
  // ck editor
  name = 'test';
  public editorOptions: any;
  mycontent: string;
  log: string = '';
  tabIndex: number = 0
  constructor(
    private fb: FormBuilder,
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private msg: NzMessageService,
    private commonArrayService: CommonArrayService,
    private ckEditorService: CkEditorConfigService,
  ) {
    this.tabIndex = this.activatedRoute.snapshot.queryParams.index;
    this.idForUpdate = this.activatedRoute.snapshot.params.id;
    this.mycontent = `<p>My html content</p>`;
    this.addUpdateTestForm = this.fb.group({
      name: [null, [Validators.required]],
      exam: [null, [Validators.required]],
      image: [null],

      testType: [
        this.commonArrayService.testsTypes[0].value,
        [Validators.required],
      ],
      difficultyLevel: [
        this.commonArrayService.difficultyLevel[0].value,
        [Validators.required],
      ],
      time: [10, [Validators.required]],
      instruction: ['', [Validators.required]],
      instructionInHindi: ['', [Validators.required]],
      examType: [
        this.commonArrayService.examsType[0].value,
        [Validators.required],
      ],
      publishedStatus: [
        this.commonArrayService.publishedStatus[0].value,
        [Validators.required],
      ],

      section: this.fb.array([]),
    });
  }
  ngOnInit(): void {
    this.editorOptions = this.ckEditorService.editorOptions
    this.getAllExams();
    this.allTestTypes = this.commonArrayService.testsTypes;
    this.allExamType = this.commonArrayService.examsType;
    this.allLevels = this.commonArrayService.difficultyLevel;
    this.allPublishedStatus = this.commonArrayService.publishedStatus;

    if (this.idForUpdate) {
      this.getTestById();
    } else {
      this.addSection();
    }
    this.searchTestType.pipe(debounceTime(1000)).subscribe((success: any) => {
      this.search = success;
    });
    this.searchExam.pipe(debounceTime(1000)).subscribe((success: any) => {
      this.examSearch = success;
      this.getAllExams();
    });
  }

  onInstructionChange(content: any) {
    this.addUpdateTestForm.patchValue({
      instruction: content.content
    }, { emitEvent: false });
  }

  onInstructionInHindiChange(content: any) {
    this.addUpdateTestForm.patchValue({
      instructionInHindi: content.content
    }, { emitEvent: false });
  }

  /* Get all exam */
  getAllExams(): void {
    const params: any = {
      skip: 0,
      limit: 100,
      status: true,
      approveStatus: 'approved'
    };
    if (this.examSearch) {
      params.search = this.examSearch;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'exams', params).subscribe(
      (result: any) => {
        this.allExams = result.data;
      },
      (err: any) => {}
    );
  }
  //  send auth in headers //
  customRequestHeaders = () => {
    return { Authorization: `Bearer ${localStorage.getItem('token')}` };
  };

  // selectedItemChange(){

  // }
  // Sections Form Array
  createSection(item?: any): FormGroup {
    if (item) {
      return this.fb.group({
        sectionName: [item.sectionName],
        // sectionDescription: [item.sectionDescription],
        time: [item.time],
        questions: [item.questions],
        subject: [''],
        chapter: [''],
        topic: [''],
        dateRange: [''],
        adminId: [''],
        difficultyLevel: [''],
        isFixedNegativeMark: [item.isFixedNegativeMark],
        negativeMarks: [item.negativeMarks],
        marks: [item.marks],
        // loadingButton:[false]
      });
    }

    return this.fb.group({
      sectionName: ['', [Validators.required]],
      // sectionDescription: ['', [Validators.required]],
      time: [''],
      questions: [''],
      subject: [''],
      chapter: [''],
      topic: [''],
      dateRange: [''],
      adminId: [''],
      difficultyLevel: [''],
      negativeMarks: [''],
      marks: [''],
      isFixedNegativeMark: [false],
      // loadingButton:[false]
    });
  }

  // for section form array
  get sectionsFormArray(): FormArray<FormGroup> {
    return this.addUpdateTestForm.get('section') as FormArray;
  }

  // Deep copy for question ref
  deepCopy(data: any): any {
    return JSON.parse(JSON.stringify(data));
  }

  // Add question
  addSection(): void {
    const data =
      this.addUpdateTestForm.value.section.length === 0
        ? 0
        : this.addUpdateTestForm.value.section.length - 1;
    if (this.addUpdateTestForm.value.section.length > 0) {
      this.addUpdateTestForm.value.section[data].questions.length > 0
        ? this.sectionsFormArray.push(this.createSection())
        : this.notificationService.error(
            '',
            'please fill the upper section First'
          );
    } else {
      this.sectionsFormArray.push(this.createSection());
    }
    this.availableQuestionsTemp.push(this.deepCopy(this.allQuestions));
  }

  // Remove
  removeSection(index: number): void {
    this.sectionsFormArray.removeAt(index);
    this.availableQuestionsTemp.splice(index, 1);
  }
  /* Get single concept details by Id */
  getTestById(): void {
    this.httpRequestService
      .request('get', `tests/${this.idForUpdate}`)
      .subscribe(
        (result: any) => {
          const questionData: Test = result.data;
          this.addUpdateTestForm.patchValue({
            // instruction: questionData.instruction,
            // instructionInHindi: questionData.instructionInHindi,
            name: questionData.name,
            image: questionData.image,
            testType: questionData.testType,
            time: questionData.time,
            exam: questionData?.exam,
            examType: questionData.examType,
            difficultyLevel: questionData.difficultyLevel,
            marks:questionData.marks,
            negativeMarks: questionData.negativeMarks,
            publishedStatus: questionData.publishedStatus,
          });
          questionData.section.forEach((element: any) => {
            // filter questions which has question value
            const questions = element.questions.filter(
              (data: any) => data.question
            );
            element.questions = questions;
            this.sectionsFormArray.push(this.createSection(element));
          });

          this.editors.forEach((editor: any) => {
            if (editor.editorID === 'instruction') {
              editor.setContents(questionData.instruction);
            } else if (editor.editorID === 'instructionInHindi') {
              editor.setContents(questionData.instructionInHindi);
            }
          });
        },
        (error: any) => {}
      );
  }
  clickChange() {
    this.switchValue = !this.switchValue;
  }
  /* Submit concept form */
  submit(): void {
    if (!this.addUpdateTestForm.valid) {
      this.markFormGroupTouched(this.addUpdateTestForm);
    } else {
      const formValue = { ...this.addUpdateTestForm.value };
      const sectionValue =
        formValue && formValue.section && formValue.section.length
          ? formValue.section
          : [];
      let sequesnceTime = 0;
      sectionValue.forEach((element: any) => {
        //
        if (element.questions.length === 0) {
          this.notificationService.error(
            '',
            ` please fill"  ${
              element.sectionName ? element.sectionName : 'Upper'
            } " section First`
          );
        }
        if (formValue.examType === 'NORMAL') {
          element.time = 0;
        } else {
          sequesnceTime = sequesnceTime + element.time;
        }
      });

      if (formValue.examType === 'SEQUENTIAL') {
        formValue.time = sequesnceTime;
      }

      if (this.idForUpdate) {
        this.addOrUpdateTest(
          'put',
          `tests/${this.idForUpdate}`,
          'Test Successfully Updated'
        );
      } else {
        this.addOrUpdateTest('post', 'tests', 'Test Added Successfully ');
      }
    }
  }

  /* Add Or Edit Concept */
  addOrUpdateTest(
    requestMethod: string,
    requestURL: string,
    successMessage: string
  ): void {
    // return
    this.buttonLoading = true;
    this.httpRequestService
      .request(requestMethod, requestURL, this.addUpdateTestForm.value)
      .subscribe(
        (result: any) => {
          this.notificationService.success('', successMessage);
          this.router.navigateByUrl('/main/test');
          this.router.navigate(['/main/test'], { queryParams: {index: this.tabIndex } });
          this.buttonLoading = false;
        },
        (error: any) => {
          if (error.error.errors) {
            const allErrors: string[] = Object.values(error.error.errors);
            for (const err of allErrors) {
              this.notificationService.error('', err);
            }
          } else {
            this.notificationService.error('', error.error.message);
          }
          this.buttonLoading = false;
        }
      );
  }
  // image Upload
  UploadImageFile(
    info: { file: NzUploadFile },
    FormControl: string,
    index: number
  ) {
    switch (info.file.status) {
      case 'uploading':
        this.imageLoading = true;
        break;
      case 'done':
        this.imageLoading = false;
        this.addUpdateTestForm.patchValue({
          image: info.file.response.data.path,
        });
        break;
      case 'error':
        this.msg.error('Network error');
        this.imageLoading = false;
        break;
    }
  }

  beforeRelatedMediaUpload = (
    file: NzUploadFile,
    fileList: NzUploadFile[]
  ): Observable<any> => {
    return new Observable((observer: Observer<boolean>) => {
      const isImage =
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/JPG' ||
        file.type === 'image/JPEG' ||
        file.type === 'image/PNG';
      if (!isImage) {
        this.msg.error('You can only upload image file!');
        observer.complete();
        return;
      }
      const isLt3M = file.size ? file.size / 1024 / 1024 < 3 : false;
      if (!isLt3M) {
        this.msg.error('Image must smaller than 3MB!');
        observer.complete();
        return;
      }
      observer.next(isImage && isLt3M);
      observer.complete();
    });
  };
  removeImage(path: string): void {
    let params: any;
    params = { path: path };
    this.addUpdateTestForm.patchValue({
      image: null,
    });
    this.httpRequestService.request( 'post', 'media/delete-media', params ).subscribe((result: any)=>{
      if(result){
        this.notificationService.success('', 'Image Deleted Successfully');
      }
    });
  }

  /* Make All Form Controls Dirty */
  private markFormGroupTouched(formGroup: FormGroup): void {
    for (const i in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(i)) {
        formGroup.controls[i].markAsDirty();
        formGroup.controls[i].updateValueAndValidity();
        // nested formgroup
        if (formGroup.controls[i] instanceof FormGroup) {
          this.markFormGroupTouched(formGroup.controls[i] as FormGroup);
        }

        // nested form array
        if (formGroup.controls[i] instanceof FormArray) {
          const formArray = formGroup.controls[i] as FormArray;
          for (const j in formArray.controls) {
            if (formArray.controls.hasOwnProperty(j)) {
              formArray.controls[j].markAsDirty();
              formArray.controls[j].updateValueAndValidity();

              if (formArray.controls[j] instanceof FormGroup) {
                this.markFormGroupTouched(formArray.controls[j] as FormGroup);
              }
            }
          }
        }
      }
    }
  }

  // seach Concept
  searchTestTypeForDropdown(event: any): void {
    this.searchTestType.next(event);
  }
  // seach Concept
  searchExamForDropdown(event: any): void {
    this.searchExam.next(event);
  }
}
